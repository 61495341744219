import React, { useEffect } from "react";
import { nav } from "../Services/Constants/navigation";
import AuthContext from "./AuthWrapper";
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
import { useContext, useState } from "react";
import { Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import FooterMenu from "./FooterMenu";
import DeskSideMenu from "./DeskSideMenu";

import AnimatedBackground from "../Components/Backgrounds/SlidingDiagonals";

export const RenderMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigation = useNavigate();
  const auth = useContext(AuthContext);
  const isAuthenticated = auth.token !== "";
  const { role_id } = useContext(AuthContext); // Include role_id from AuthContext

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  // Function to handle click outside the menu
  const handleOverlayClick = (e) => {
    if (isMenuOpen) {
      closeMenu();  // Close the menu if the overlay is clicked
    }
  };

  const MenuItem = ({ route }) => {
    const location = useLocation();
    const isActive = route.path === location.pathname;
    const isRentYourSpace = route.name === "Rent your space";

    return (
      <div
        className={`menuItem ${isActive ? "active" : ""} ${isRentYourSpace ? "rentYourSpace" : ""}`}
        onClick={() => {
          navigation(route.path);
          if (window.innerWidth <= 768) {
            toggleMenu(); // Close menu on item click for mobile
          }
        }}
      >
        <span>{route.name}</span>
      </div>
    );
  };

  return (
    <div key={role_id}>
      {/* Overlay for outside click */}
      {isMenuOpen && <div className="overlay" onClick={handleOverlayClick} />}

      {/* Side Menu for Mobile */}
      <div className={`side-menu ${isMenuOpen ? "open" : ""}`}>
        <div className="menu-header">
          <div className="logo" onClick={() => {
            toggleMenu();
            navigation("/");
            //showSnackbar("Navigated to Home", "success");
          }}>
            <img
              className="noLogLogo"
              src={require("./../assets/logo_light.png")}
              alt="Logo"
            />
          </div>
          <div className="close-menu-btn" onClick={toggleMenu}>
            <FaTimes />
          </div>
        </div>
        <div className="menu-items">
          {nav.map((route, i) => {
            // Exclude "Setup HopperSite" link if the role_id is "trainer"
            //console.log("Site or Train ???", role_id );
            if (route.path === "/SiteOnboarding" && role_id === 3) {
              //console.log("Hiding SiteOnboarding for role", role_id );
              return null;
            } else if (route.path === "/TrainerOnboarding" && role_id === 2) {
              //console.log("Hiding /TrainerOnboarding for role", role_id );
              return null;
            }

            if (!route.isPrivate && route.isMenu) {
              return <MenuItem key={i} route={route} />;
            } else if (isAuthenticated && route.isMenu) {
              return <MenuItem key={i} route={route} />;
            } else return false;
          })}

          {/* Register and Login */}
          {!isAuthenticated && (
            <>
              <MenuItem route={nav[1]} />
              <MenuItem route={nav[2]} />
            </>
          )}
        </div>
      </div>

      <FooterMenu menuClick={toggleMenu}></FooterMenu>
    </div>
  );
};

export const RenderRoutes = () => {
  const [activeScreen, setActiveScreen] = useState('Setup');
  const { role_id } = useContext(AuthContext);

  const auth = useContext(AuthContext);
  const isAuthenticated = auth.token !== "";

  const navigation = useNavigate();
  const location = useLocation();

  const handleMenuClick = (menuItem) => {

    console.log(menuItem);
    const onboardingPrefix = role_id === 3 ? "/TrainerOnboarding" : "/SiteOnboarding";

    //setActiveScreen(menuItem);
    // Logic to navigate based on the menu item clicked
    switch (menuItem) {
      case "List Your Space":
        navigation("/SiteOnboarding");
        break;
      case "Trainer Setup":
        navigation("/TrainerOnboarding");
        break;
      case "Setup":
        navigation(`${onboardingPrefix}#intro`);
        break;
      case "Description":
        navigation(`${onboardingPrefix}#description`);
        break;
      case "Gallery":
        navigation(`${onboardingPrefix}#gallery`);
        break;
      case "Space":
        navigation(`${onboardingPrefix}#space`);
        break;
      case "Amenities":
        navigation(`${onboardingPrefix}#amenities`);
        break;
      case "Location":
        navigation(`${onboardingPrefix}#location`);
        break;
      case "Schedule":
        navigation(`${onboardingPrefix}#schedule`);
        break;
      case "Special":
        navigation(`${onboardingPrefix}#special`);
        break;
      case "SetupComplete":
        navigation(`${onboardingPrefix}#setupcomplete`);
        break;

      // Trainer-specific items
      case "Certification":
        navigation(`${onboardingPrefix}#certification`);
        break;
      case "Insurance":
        navigation(`${onboardingPrefix}#insurance`);
        break;
      case "DemographicInfo":
        navigation(`${onboardingPrefix}#demographicinfo`);
        break;
      case "Coach":
        navigation(`${onboardingPrefix}#coach`);
        break;
      case "Goal":
        navigation(`${onboardingPrefix}#goal`);
        break;
      case "Area":
        navigation(`${onboardingPrefix}#area`);
        break;
      case "Motivate":
        navigation(`${onboardingPrefix}#motivate`);
        break;
      case "ProfilePic":
        navigation(`${onboardingPrefix}#profilepic`);
        break;
      case "Success":
        navigation(`${onboardingPrefix}#success`);
        break;

      // Common items
      case "Earnings":
        navigation("/Wallet");
        break;
      case "Home":
        navigation("/");
        break;
      case "settings":
        navigation("/Profile");
        break;
      case "Settings":
        navigation("/Profile");
        break;
      case "UserType":
        navigation("/UserType");
        break;

      default:
        navigation(`/${menuItem.toLowerCase()}`); // Fallback to default screen
    }
  };

  useEffect(() => {
    const loginOrRegisterRoutes = ["/","/Login", "/Register"];
    const dashRoutes = ["/Dashboard", "/TrainerDash", "/GymDash"];
    const onboardingRoutes = ["/SiteOnboarding", "/TrainerOnboarding"];

    if ((role_id === "0" || role_id === 0) && loginOrRegisterRoutes.includes(location.pathname) && isAuthenticated) {
      navigation("/UserType");
    } else if (loginOrRegisterRoutes.includes(location.pathname) && isAuthenticated) {
      navigation("/Dashboard");
    } else if (dashRoutes.includes(location.pathname) && (role_id === "0" || role_id === 0)) {
      navigation("/UserType");
    } else if (onboardingRoutes.includes(location.pathname) && (role_id === "0" || role_id === 0)) {
      navigation("/UserType");
    }
  }, [role_id, location.pathname, navigation]);

  const filteredNav = nav.filter((route) => {

    //console.log(route.path); 
    if (route.path === "/SiteOnboarding" && role_id === "3") {
      //console.log(route.path,true); 
      return false;
    }
    if (route.path === "/TrainerOnboarding" && role_id === "2") {
      return false;
    }

    return true;
  });

  return (
    <>
      <DeskSideMenu onMenuClick={handleMenuClick} activeScreen={activeScreen} />
      <div className="main-content-container">
        <AnimatedBackground />
        <Routes>
          {filteredNav.map((route, i) => {
            if (route.isGuestAllowed) {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={<GuestRoute>{React.cloneElement(route.element, { activeScreen, setActiveScreen })}</GuestRoute>}
                />
              );
            } else if (route.isPrivate) {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={<PrivateRoute>{React.cloneElement(route.element, { activeScreen, setActiveScreen })}</PrivateRoute>}
                />
              );
            } else if (!route.isPrivate) {
              return <Route key={i} path={route.path} element={React.cloneElement(route.element, { activeScreen, setActiveScreen })} />;
            } else return false;
          })}
        </Routes>
      </div>
    </>
  );

};
