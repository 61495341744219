import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../../config";

// Async thunk for fetching the gallery
export const fetchMyGalleryAsync = createAsyncThunk(
  "gallery/fetchMyGallery",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}profile/getMyGallery/${token}`
      );
      const gallery = response.data?.map(({ date, url, picture_id }) => ({
        date,
        url,
        picture_id,
      }));
      return gallery;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch gallery");
    }
  }
);

const GallerySlice = createSlice({
  name: "gallery",
  initialState: {
    gallery: [],
    loading: false,
    error: null,
  },
  reducers: {
    categorizeByDate: (state, action) => {
      // Add logic for categorizing the gallery by date
      const categorizedGallery = action.payload.reduce((acc, item) => {
        const { date } = item;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      }, {});
      state.gallery = categorizedGallery;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyGalleryAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyGalleryAsync.fulfilled, (state, action) => {
        state.gallery = action.payload;
        state.loading = false;
      })
      .addCase(fetchMyGalleryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { categorizeByDate } = GallerySlice.actions;

export default GallerySlice.reducer;