import React, { useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck, FaCircle } from "react-icons/fa";
import { MdOutlineQuestionMark } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import moment from "moment";

import calendarReschedule from "../../assets/calendar-reschedule.png";
import { cancelBooking } from "../../Services/APIHandlers/ScheduleApi";
import getErrorMessage from "../../Services/Helpers/getErrorMessage";
import { checkInBooking } from "../../Services/APIHandlers/ScheduleApi";

import {
  formatBookingTime,
  calculateTimeRemaining,
  isCheckInTime,
} from "../../Services/Helpers/CalendarHelpers";

const ConfirmedCard = ({ bookingData, hosting, getSchedule }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);
  const [cancelling, setCancelling] = useState(false);
  const [checkingIn, setCheckingIn] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false);

  const theme = useTheme();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    console.log(bookingData);
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelBooking = async () => {
    try {
      setCancelling(true);
      const { data } = await cancelBooking(token, bookingData?.booking?.id);
      console.log(">>cancelBooking", data);

      if (data?.error && data?.message) {
        alert(data.message);
      }
    } catch (error) {
      console.error(">>>Error in handleCancelBooking", error);
      alert(getErrorMessage(error));
    } finally {
      setCancelling(false);
      getSchedule();
    }
  };

  const handleCheckIn = async () => {
    try {
      setCheckingIn(true);
      const { data } = await checkInBooking(token, bookingData?.booking?.id);
      console.log(">>checkInBooking", data);

      if (data?.error && data?.message) {
        alert(data.message);
      } else {
        setCheckedIn(true); // Mark as checked-in on success
      }
    } catch (error) {
      console.error(">>>Error in handleCheckIn", error);
      alert(getErrorMessage(error));
    } finally {
      setCheckingIn(false);
      getSchedule();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        p: 2,
        boxShadow: theme.shadows[1],
      }}
    >
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: 1,
            p: "2px 5px",
          }}
        >
          <FaCircle color={theme.palette.success.main} size={13} />
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            fontWeight={500}
            mr={1}
          >
            Check-in {calculateTimeRemaining(bookingData.booking.start_time)}
          </Typography>
        </Box>
        <IconButton onClick={handleMenuOpen}>
          <BsThreeDotsVertical color={theme.palette.text.primary} size={25} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={menuVisible}
          onClose={handleMenuClose}
          PaperProps={{ sx: { borderRadius: 2, mt: 1 } }}
        >
          <MenuItem onClick={handleMenuClose}>
            <FaCircle
              size={18}
              color={theme.palette.success.main}
              style={{ marginRight: 10 }}
            />
            Complete
          </MenuItem>
          <MenuItem onClick={handleCancelBooking} disabled={cancelling}>
            <IoIosCloseCircleOutline
              size={18}
              color={theme.palette.error.main}
              style={{ marginRight: 10 }}
            />
            Cancel
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose}>
            <img
              src={calendarReschedule}
              style={{ width: 18, height: 18, marginRight: 10 }}
              alt="Calendar Reschedule"
            />
            Reschedule
          </MenuItem> */}
          <MenuItem onClick={handleMenuClose}>
            <MdOutlineQuestionMark
              size={18}
              color={theme.palette.warning.main}
              style={{ marginRight: 10 }}
            />
            No Show
          </MenuItem>
        </Menu>
      </Box>
      {/* First Row: Time and Menu */}
      <Box
        display="flex"
        mb={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="body1"
          fontWeight={600}
          color={theme.palette.text.secondary}
        >
          {bookingData?.booking?.start_time &&
            (() => {
              const { startDate } = formatBookingTime(
                bookingData.booking.start_time,
                bookingData.booking.end_time
              );
              return `${startDate}`;
            })()}
        </Typography>
        <Typography variant="body1" color={theme.palette.text.secondary}>
          {bookingData?.booking?.start_time &&
            (() => {
              const { formattedTime } = formatBookingTime(
                bookingData.booking.start_time,
                bookingData.booking.end_time
              );
              return `${formattedTime}`;
            })()}
        </Typography>
      </Box>

      {/* First Row: Trainer Image and Details */}
      {hosting && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          mb={2}
        >
          <Box display="flex" alignItems="center" gap={2}>
            {/* Trainer Image */}
            <Avatar
              src={bookingData?.booking_user?.image}
              sx={{ width: 45, height: 45, borderRadius: "50%" }}
              alt="Trainer"
            />
            {/* Trainer Info */}
            <Box sx={{ textAlign: "left" }}>
              <Typography
                variant="body1"
                fontWeight={600}
                color={theme.palette.text.primary}
              >
                {bookingData?.booking_user?.name}{" "}
                {bookingData?.booking_user?.last_name}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {/* Gym and Location Details */}
      {!hosting && (
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          mb={2}
          textAlign="left"
        >
          <Typography variant="h6" color={theme.palette.text.primary}>
            {bookingData?.space?.name}
          </Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {bookingData?.location?.address} {/* Location */}
          </Typography>
        </Box>
      )}

      {/* Bottom Section: Actions */}
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        flexDirection="column"
      >
        <Box>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ textAlign: "right", mb: 1 }}
          >
            {!hosting && (
              <>
                Paid: <b>${bookingData.booking.payout / 100}</b>{" "}
              </>
            )}
            {hosting && (
              <>
                Payout: <b>${bookingData.booking.payout / 100}</b>{" "}
              </>
            )}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            width: "100%",
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
            textTransform: "none",
            p: "6px 17px",
            fontSize: "0.875rem",
            fontWeight: 500,
            borderRadius: 1,
          }}
          enabled={!isCheckInTime(bookingData.booking.start_time)}
          onClick={handleCheckIn}
        >
          Check-in
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmedCard;
