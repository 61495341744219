import React, { useState,useEffect } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { globalStyles, spacingStyles } from "../../assets/styles/global";
import { useTheme } from "@mui/material/styles"; // Import useTheme hook
import { useDispatch, useSelector } from "react-redux";
import {
  setDedicatedSpace,
  setChargeRate,
} from "../../Services/Redux/Slices/Onboarding.Slice";
import OnboardingButton from "../Common/Buttons/OnboardingButton";
import RateInput from "../Common/RateInput";
import { convertCentsToDollars, convertDollarToCents } from "../../Services/Helpers/FinanceHelpers";

export default function Space() {
  const dispatch = useDispatch();
  const theme = useTheme(); // Access the current theme

  const space = useSelector((state) => state.onboarding.space);
  const [chargeRate, setChargeRateLocal] = useState(space.chargeRate);

  useEffect((()=>{
    setChargeRateLocal(space.chargeRate);
  }),[space]);

  const handleChargeRateChange = (e) => {
    const value = parseInt(e.target.value);
    console.log(value);
    setChargeRateLocal(convertDollarToCents(value));
    dispatch(setChargeRate(convertDollarToCents(value)));
  };

  const incrementChargeRate = () => {
    const newValue = chargeRate + 1;
    setChargeRateLocal(newValue);
    dispatch(setChargeRate(newValue));
  };

  const decrementChargeRate = () => {
    const newValue = chargeRate > 0 ? chargeRate - 1 : 0;
    setChargeRateLocal(newValue);
    dispatch(setChargeRate(newValue));
  };

  const dedicatedSpace = useSelector(
    (state) => state.onboarding.space.dedicatedSpace
  );

  return (
    <Stack
      spacing={4}
      sx={{ textAlign: "left", width: "100%", pb:6}}
    >
      <Box sx={{ ...spacingStyles.mauto, ...spacingStyles.pb40 }}>
        <Typography
          variant="h5"
          sx={{  color: theme.palette.text.primary }} // Dynamic color based on theme
        >
          What is the rent price?
        </Typography>
        <Typography variant="body1" style={{ ...spacingStyles.mb20 }} color={theme.palette.text.secondary}>
          Input your hourly rate
        </Typography>
        <RateInput
          chargeRate={chargeRate}
          handleChargeRateChange={handleChargeRateChange}
          incrementChargeRate={incrementChargeRate}
          decrementChargeRate={decrementChargeRate}
        />

      </Box>

      <Box>
        <Typography
          variant="h5"
          sx={{ color: theme.palette.text.primary }} // Dynamic color based on theme
        >
          What is the best way to describe your space?
        </Typography>
        <Typography variant="body1" style={{ ...spacingStyles.mb20 }} color={theme.palette.text.secondary}>
          Select the option that applies
        </Typography>
        <Box
          sx={{
            pt: 2,
            pb: 2,
            ...globalStyles.w100,
            textAlign: "left",
          }}
        >
          <OnboardingButton
            showCusIcon={false}
            onClick={() => dispatch(setDedicatedSpace(true))}
            text={"Share Space"}
            selected={dedicatedSpace}
            pinName=""
          />

          <OnboardingButton
            showCusIcon={false}
            onClick={() => dispatch(setDedicatedSpace(false))}
            text={"Private Space"}
            selected={!dedicatedSpace}
            pinName=""
          />
        </Box>

        {/* Conditional text display based on dedicatedSpace */}
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary, // Dynamic color based on theme for lighter text
            // ...spacingStyles.ml10,
            marginTop: "10px",
          }}
        >
          {dedicatedSpace
            ? "Ex. Area such as a gym floor with equipment or free weights."
            : "Ex. Area where Hoppers will have control over who enters the room. Example: spinning room with sound system."}
        </Typography>
      </Box>
    </Stack>
  );
}
