import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MdCalendarMonth } from "react-icons/md";

import { Box, Typography, Grid, Tabs as MuiTabs, Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  colors,
  globalStyles,
  spacingStyles,
} from "../../../assets/styles/global.js";

import ConfirmedCard from "../../../Components/Bookings/ConfirmedCard";
import PendingCard from "../../../Components/Bookings/PendingCard";
import UpcomingCard from "../../../Components/Bookings/UpComingCard";
import PreviousCard from "../../../Components/Bookings/PreviousCard";
import CanceledCard from "../../../Components/Bookings/CanceledCard";

import { fetchSchedule } from "../../../Services/APIHandlers/ScheduleApi";
import BookingsWeekCalendar from "../../../Components/Bookings/BookingsWeekCalendar";
import Loader from "../../../Components/Common/Loader";
import moment from "moment";

const Bookings = () => {
  const theme = useTheme();
  const token = useSelector((state) => state.auth.token);
  const role_id = useSelector((state) => state.auth.role_id);

  //console.log("Current Role ID:", role_id);

  const [selectDate, setSelectDate] = useState(moment());
  const [isLoading, setLoading] = useState(false);
  const [bookedMe, setBookedMe] = useState([]);
  const [myBookings, setMyBookings] = useState([]);
  const [activeTab, setActiveTab] = useState("REQUEST");

  const [mode, setMode] = useState("BookedMe");

  const availableTabs = useMemo(() => {
    return mode === "MyBookings"
      ? ["REQUEST", "UPCOMING", "CANCELED", "COMPLETED"]
      : [ "REQUEST", "APPROVED", "CANCELED", "COMPLETED"];
  }, [mode, role_id]);

  useEffect(() => {
    if (role_id === "3") {
      setMode("MyBookings");
    } else {
      setMode("BookedMe");
    }
  }, [role_id]);

  const getSchedule = async () => {
    try {
      setLoading(true);
      const { data } = await fetchSchedule(token);
      console.log(data);
      setMyBookings(data.myBookings);
      setBookedMe(data.bookedMe);
    } catch (error) {
      console.log(">>>Error in getSchedule", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSchedule();
  }, [token]);

  const filteredData = useMemo(() => {
    let data = [...myBookings, ...bookedMe];

    // Apply filters based on the active tab
    switch (activeTab) {
      case "APPROVED":
        return data.filter(
          (booking) =>
            (booking.booking.status === "confirmed" ||
              booking.booking.status === "approved") &&
            moment(booking.booking.date).isSameOrAfter(moment())
        );
      case "UPCOMING":
        return data.filter(
          (booking) =>
            (booking.booking.status === "confirmed" ||
              booking.booking.status === "approved") &&
            moment(booking.booking.date).isSameOrAfter(moment())
        );
      case "COMPLETED":
        return data.filter(
          (booking) =>
            booking.booking.status === "completed" ||
            booking.booking.status === "checkin"
          //   &&
          // moment(booking.booking.date).isBefore(moment())
        );
      case "REQUEST":
        return data.filter(
          (booking) =>
            ["booked"].includes(booking.booking.status) &&
            moment().isBefore(booking.booking.date)
        );
      case "PENDING":
        return data.filter((booking) =>
          ["pending", "booked"].includes(booking.booking.status)
        );
      case "CANCELED":
        return data.filter((booking) => booking.booking.status === "canceled");
      default:
        return [];
    }
  }, [activeTab, mode, myBookings, bookedMe]);

  const tabContentMap = {
    APPROVED: ConfirmedCard,
    UPCOMING: ConfirmedCard,
    COMPLETED: PreviousCard,
    REQUEST: PendingCard,
    PENDING: PendingCard,
    CANCELED: CanceledCard,
  };

  // const tabContentMap = {
  //   APPROVED: {
  //     items: filteredBookedMeConfirmed,
  //     CardComponent: ConfirmedCard,
  //     emptyMessage: "No approved bookings.",
  //   },
  //   UPCOMING: {
  //     items: filteredBookedMeConfirmed,
  //     CardComponent: ConfirmedCard,
  //     emptyMessage: "No upcoming bookings.",
  //   },
  //   REQUEST: {
  //     items: filteredBookedMePending,
  //     CardComponent: PendingCard,
  //     emptyMessage: "No requested bookings.",
  //   },
  //   PENDING: {
  //     items: filteredBookedMePending,
  //     CardComponent: PendingCard,
  //     emptyMessage: "No pending bookings.",
  //   },
  //   CANCELED: {
  //     items: filteredBookedMeCanceled,
  //     CardComponent: CanceledCard,
  //     emptyMessage: "No canceled bookings.",
  //   },
  //   PREVIOUS: {
  //     items: filteredPrevious,
  //     CardComponent: PreviousCard,
  //     emptyMessage: "No previous bookings.",
  //   },
  // };
  const handleTabChange = (event, newValue) => setActiveTab(newValue);
  const handleModeChange = (newMode) => {
    setMode(newMode);
    setActiveTab(newMode === "MyBookings" ? "UPCOMING" : "APPROVED");
  };

  const Tabs = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <MuiTabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
        >
          {availableTabs.map((tabName) => (
            <Tab key={tabName} label={tabName} value={tabName} />
          ))}
        </MuiTabs>
      </Box>
    );
  };

  const EmptyComponent = () =>
    isLoading ? (
      <Loader />
    ) : (
      <div
        style={{
          gap: 10,
          marginTop: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            ...globalStyles.h1,
            color: colors.dark_gray.color,
          }}
        >
          <Typography
            variant="h6"
            style={{
              color: colors.dark_gray.color,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            No bookings available.
          </Typography>
        </div>
        <MdCalendarMonth size={40} color={colors.dark_gray.color} />
      </div>
    );

  return (
    <div style={{ ...spacingStyles.desktopMaxWidth }}>
      <Box sx={{ padding: "20px 0 40px" }}>
        <Typography
          sx={{ maxWidth: "100vw", paddingBottom: "40px" }}
          variant="h4"
          color={theme.palette.text.primary}
        >
          Bookings
        </Typography>

        {/* Mode Switch */}
        {/* <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <button
            onClick={() => handleModeChange("BookedMe")}
            style={{
              ...globalStyles.button,
              backgroundColor:
                mode === "BookedMe" ? colors.primary.color : "#ccc",
            }}
          >
            Hosting
          </button>
          <button
            onClick={() => handleModeChange("MyBookings")}
            style={{
              ...globalStyles.button,
              backgroundColor:
                mode === "MyBookings" ? colors.primary.color : "#ccc",
            }}
          >
            My Request
          </button>
        </Box> */}

        {/* Tabs */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <MuiTabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            textColor="primary"
            indicatorColor="primary"
          >
            {availableTabs.map((tabName) => (
              <Tab key={tabName} label={tabName} value={tabName} />
            ))}
          </MuiTabs>
        </Box>

        {/* Tab Content */}
        {availableTabs.includes(activeTab) && (
          <Grid container columnSpacing={5} rowSpacing={2} width="100%">
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <Grid
                  key={item.booking.id + item.booking.status + index}
                  item
                  md={6}
                  width="100%"
                >
                  {React.createElement(
                    tabContentMap[activeTab],
                    {
                      bookingData: item,
                      hosting: mode === "BookedMe" ? true : false,
                      getSchedule,
                    },
                    null
                  )}
                </Grid>
              ))
            ) : (
              <EmptyComponent />
            )}
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default Bookings;
