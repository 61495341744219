import axios from "axios";
import config from '../../config';

export const getClientSecret = async (bookingID, token) => {
  console.log(bookingID);
  const response = await axios.get(config.apiUrl + "Stripe/checkOut/"+bookingID+"/"+token);
  return response;
};

export const getStartTrial = async (token) => {
  const response = await axios.get(config.apiUrl + "Stripe/startTrial/"+token);
  return response;
};


