function getDayIDX(daySelect) {
  let dayIDX = 0;
  //console.log(daySelect);
  switch (daySelect) {
    case "Sun":
      dayIDX = 0;
      break;
    case "Mon":
      dayIDX = 1;
      break;
    case "Tue":
      dayIDX = 2;
      break;
    case "Wed":
      dayIDX = 3;
      break;
    case "Thu":
      dayIDX = 4;
      break;
    case "Fri":
      dayIDX = 5;
      break;
    case "Sat":
      dayIDX = 6;
      break;
  }

  return dayIDX;
}
export default getDayIDX;
