import moment from "moment";
import { useSelector } from "react-redux";
//import { selectDate } from '../../Services/Redux/Slices/Booking.Slice.js';

export const hasClassBooking = (day) => {
  return false;
};

export const isSelectDate = (date1, date2) => {
  // Convert both dates to Date objects if they are not already
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Compare year, month, and day components
  //const isSameYear = d1.getFullYear() === d2.getFullYear();
  //const isSameMonth = d1.getMonth() === d2.getMonth();
  const isSameDay = d1.getDate() === d2.getDate();

  if (isSameDay) {
    return true;
  } else {
    return false;
  }
};

export const hasPast = (daytime) => {
  const currentDatetime = new Date();
  const checkTime = new Date(daytime);
  if (currentDatetime > checkTime) {
    return true;
  } else {
    return false;
  }
};

export const hasAvailability = (day, schedule) => {
  //const shortDay = new Date(day).toLocaleDateString('en-US', { weekday: 'short' });
  const shortDay = moment(day).format("ddd");
  let rtrn = false;

  // Access the specific day's schedule using the formatted shortDay as key
  let daySchedule = [];

  if (Array.isArray(schedule)) {
    for (let arr of schedule) {
      if (arr[shortDay]) {
        daySchedule = arr[shortDay];
        break;
      }
    }
  } else {
    daySchedule = schedule[shortDay];
  }

  return daySchedule?.some((timeSlot) => timeSlot.select === true) || false;
};

export const isToday = (day) => {
  const currentDatetime = new Date();
  if (
    moment(day).format("MMM Do YY") ===
    moment(currentDatetime).format("MMM Do YY")
  ) {
    return true;
  } else {
    return false;
  }
};

export const isBlackOut = (day, blackOuts) => {
  let rtrn = false;
  for (const blackday in blackOuts) {
    if (
      moment(day).format("MMM Do YY") === moment(blackday).format("MMM Do YY")
    ) {
      rtrn = true;
      break;
    }
  }
  return rtrn;
};

export const getDayIDX = (daySelect) => {
  let dayIDX = 0;
  //console.log(moment(daySelect).format("ddd"));
  switch (moment(daySelect).format("ddd")) {
    case "Sun":
      dayIDX = 0;
      break;
    case "Mon":
      dayIDX = 1;
      break;
    case "Tue":
      dayIDX = 2;
      break;
    case "Wed":
      dayIDX = 3;
      break;
    case "Thu":
      dayIDX = 4;
      break;
    case "Fri":
      dayIDX = 5;
      break;
    case "Sat":
      dayIDX = 6;
      break;
    default:
      dayIDX = 0;
  }

  return dayIDX;
};

export const formatTime = (time) => {
  //console.log(time);
  if (time !== "12:00:00") {
    const currentDate = moment().format("YYYY-MM-DD");
    const combinedDateTime = `${currentDate} ${time}`;
    const selectDate = new Date(combinedDateTime);
    const formatted = moment(selectDate).format("h:mm");
    return formatted;
  } else {
    return "Noon";
  }
};

export const getMonthDays = (start) => {
  const month = [];
  let monthDay = moment(start).startOf("month");
  const initMonth = monthDay.month();

  while (monthDay.month() === initMonth || month.length < 6) {
    let week = getWeek(monthDay);
    month.push(week);
    monthDay = moment(monthDay).add(1, "week");
  }

  return month;
};

export const getWeek = (monthDay) => {
  let start = moment(monthDay).startOf("week");
  let week = [];
  for (let day = 0; day < 7; day++) {
    week.push(moment(start).add(day, "day")); // Push Moment.js object for each day
  }
  return week;
};


export const formatBookingTime = (startTime, endTime) => {
  if (!startTime) return "";

  const startDate = moment(startTime).format("ddd, MMM Do");
  let formattedTime = moment(startTime).format("hh:mma");
  if (endTime) {
    formattedTime += " - " + moment(endTime).format("hh:mma");
  }

  return { startDate, formattedTime };
};

export const calculateTimeRemaining = (startTime) => {
  if (!startTime) return "";

  const now = moment();
  const startMoment = moment(startTime);

  const diffInMinutes = startMoment.diff(now, "minutes");

  if (diffInMinutes >= 1440) {
    const days = Math.floor(diffInMinutes / 1440);
    return `${days} Days`;
  } else if (diffInMinutes >= 60) {
    const hours = Math.floor(diffInMinutes / 60);
    return `${hours} Hrs`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} Mins`;
  }

  return "Expired";
};

export const isCheckInTime = (startTime) => {
  if (!startTime) return false;

  const now = moment();
  const startMoment = moment(startTime);

  const diffInMinutes = startMoment.diff(now, "minutes");

  // Check if start_time is in the past or within the next hour
  console.log("isCheckInTime",(diffInMinutes <= 61 && diffInMinutes >= 0) ); 
  return diffInMinutes <= 61 && diffInMinutes >= 0;
};