import Login from "../../Screens/Authentication/Login";
import Register from "../../Screens/Authentication/Register";
import UserType from "../../Screens/Authentication/UserType";
import OTP from "../../Screens/Authentication/OTP";
import MapSearch from "../../Screens/Map/Search";

import Profile from "../../Screens/Common/Profile/Profile";
import GymDetails from "../../Screens/Users/Gym/GymDetails";
import TrainerDetails from "../../Screens/Users/Trainer/TrainerDetails";
// import Checkout from "../../Screens/Public/Checkout";
import ProfileForward from "../../Screens/Users/ProfileForward";
import ConfirmationScreen from "../../Screens/Public/ConfirmScreen";
import Wallet from "../../Screens/Common/Wallet";

import TrainerDash from "../../Screens/Users/Trainer/TrainerDash";
import GymDash from "../../Screens/Users/Gym/GymDash";
import ThirdPartyRegister from "../../Screens/Authentication/ThirdPartyRegister";
import Bookings from "../../Screens/Common/Profile/Bookings";
import TrainerOnboarding from "../../Screens/Wizard/TrainerOnboarding";
import SiteOnboarding from "../../Screens/Wizard/SiteOnboarding";
import SimilarFitnessTrainers from "../../Screens/Wizard/SiteOnboarding/SimilarFitnessTrainers";
import UserCalendar from "../../Screens/Common/Calendar/UserCalendar";
import EditBlackOutDates from "../../Screens/Common/Calendar/EditBlackOutDates";
import EditSchedule from "../../Screens/Common/Calendar/EditSchedule";
import OnboardingLocation from "../../Components/Onboarding/OnboardingLocation";
import DashboardRedirect from "../../Screens/Users/DashboardRedirect";

// import HomeIcon from '@mui/icons-material/Home';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PaidIcon from '@mui/icons-material/Paid';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
// import OperationTime from "../Components/Calendar/OperationTimeGrid";
// import Location from "../Screens/Common/Profile/Location";

export const nav = [
  {
    path: "/",
    name: "Login",
    element: <Login />,
    isMenu: false,
    isPrivate: false,
    icon: <EventAvailableIcon />
  },
  {
    path: "/Login",
    name: "Login",
    element: <Login />,
    isMenu: false,
    isPrivate: false,
    icon: <EventAvailableIcon />
  },
  {
    path: "/Register",
    name: "Register",
    element: <Register />,
    isMenu: false,
    isPrivate: false,
    icon: <EventAvailableIcon />
  },
  {
    path: "/ThirdPartyRegister",
    name: "ThirdPartyRegister",
    element: <ThirdPartyRegister />,
    isMenu: false,
    isPrivate: false,
    icon: <EventAvailableIcon />
  },
  {
    path: "/UserType",
    name: "UserType",
    element: <UserType />,
    isMenu: false,
    isPrivate: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/OTP",
    name: "OTP",
    element: <OTP />,
    isMenu: false,
    isPrivate: false,
    icon: <EventAvailableIcon />
  },
  {
    path: "/SiteOnboarding",
    name: "List Your Space",
    element: <SiteOnboarding />,
    isMenu: true,
    isPrivate: true,
    isGuestAllowed: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/TrainerOnboarding",
    name: "Trainer Setup",
    element: <TrainerOnboarding />,
    isMenu: true,
    isPrivate: true,
    isGuestAllowed: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/Search",
    name: "Search",
    element: <MapSearch />,
    isMenu: true,
    hasMenu: true,
    isPrivate: false,
    icon: <SearchIcon />
  },
  {
    path: "/Location",
    name: "Location",
    element: <OnboardingLocation radiusOpt="true" />,
    //element: <Location />,
    isMenu: false,
    isGuestAllowed: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/Availability",
    name: "Availabilty",
    // element: <OperationTime />,
    element: <UserCalendar />,
    isMenu: false,
    isPrivate: true,
    hasMenu: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/EditBlackoutDates",
    name: "Blackout Dates",
    element: <EditBlackOutDates />,
    isMenu: false,
    isPrivate: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/EditSchedule",
    name: "Schedule",
    element: <EditSchedule />,
    isMenu: false,
    isPrivate: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/Bookings",
    name: "Bookings",
    element: <Bookings />,
    isMenu: true,
    isPrivate: true,
    icon: <EventAvailableIcon />
  },
  // {
  //   path: "/Profile/:userId",
  //   name: "Profile",
  //   element: <Profile />,
  //   isMenu: false,
  //   isPrivate: true,
  // },
  {
    path: "/GymDetails/:slug",
    name: "GymDetails",
    element: <GymDetails />,
    isMenu: false,
    isPrivate: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/TrainerDetails/:slug",
    name: "TrainerDetails",
    element: <TrainerDetails />,
    isMenu: false,
    isPrivate: true,
    icon: <EventAvailableIcon />
  },
  // {
  //   path: "/Checkout",
  //   name: "Checkout",
  //   element: <Checkout />,
  //   isMenu: false,
  //   isPrivate: true,
  //   icon: <EventAvailableIcon />
  // },
  {
    path: "/Confirm",
    name: "Confirm",
    element: <ConfirmationScreen />,
    isMenu: false,
    isPrivate: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    element: <DashboardRedirect />,
    isMenu: false,
    isPrivate: true,
    isGuestAllowed: false,
    icon: <EventAvailableIcon />
  },
  {
    path: "/TrainerDash",
    name: "TrainerDash",
    element: <TrainerDash />,
    isMenu: false,
    isPrivate: true,
    isGuestAllowed: false,
    icon: <EventAvailableIcon />
  },
  {
    path: "/GymDash",
    name: "GymDash",
    element: <GymDash />,
    isMenu: false,
    isPrivate: true,
    isGuestAllowed: false,
    icon: <EventAvailableIcon />
  },
  {
    path: "/Wallet",
    name: "Earnings",
    element: <Wallet />,
    isMenu: true,
    isPrivate: true,
    hasMenu: true,
    icon: <PaidIcon />
  },
  {
    path: "/Profile",
    name: "Settings",
    element: <Profile />,
    isMenu: true,
    hasMenu: true,
    isPrivate: true,
    icon: <SettingsIcon />
  },

  {
    path: "/SimilarFitnessTrainers",
    name: "SimilarFitnessTrainers",
    element: <SimilarFitnessTrainers />,
    isMenu: false,
    isPrivate: true,
    icon: <EventAvailableIcon />
  },
  {
    path: "/:slug",
    name: "Profile",
    element: <ProfileForward />,
    isMenu: false,
    isPrivate: false,
    isGuestAllowed: false,
    icon: <EventAvailableIcon />
   
  },
];
