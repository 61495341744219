import { createTheme} from "@mui/material/styles";

export const useDynamicTheme = (baseTheme, mode) => {
  return createTheme({
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      mode: mode, // Override the mode
    },
  });
};