import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Navigation/AuthWrapper"; // Adjust path as needed
import RegisterForm from "../../Components/Forms/RegisterForm";
import OTPForm from "./OTPForm";
import { Button, Box, Typography, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { addTermAgreementRequest } from "../../Services/APIHandlers/ProfileApi";



const TermAgreementForm = ({ slug, mode = "light" }) => {

  const navigate = useNavigate();
  const theme = useTheme();
  const formMode = theme.palette.mode;

  const [termOption, setTermOption] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [showAuthForm, setShowAuthForm] = useState(false);
  //const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isOTPConfirmed, setIsOTPConfirmed] = useState(false);

  const auth = useContext(AuthContext);

  useEffect(() => {
    setShowAuthForm(false);
  }, []);
  useEffect(() => {
    setIsLogged(auth.token !== "");
  }, [auth.token]);

  const termOptions = [
    "1 day - 1 week",
    "2 - 4 weeks",
    "1 - 3 months",
    "3 - 6 months",
    "6 months - 1 year",
  ];

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!isLogged) {
      setShowAuthForm(true);
    } else {
      //alert(`You have selected: ${termOption}`);
      // Handle logged-in form submission logic here
      saveRequest();
    }
  };

  const handleRegistrationSuccess = () => {
    setIsRegistered(true)
  };
  const handleOTPSuccess = () => {
    setIsOTPConfirmed(true);
    setIsLogged(true);
    saveRequest();
  };

  const saveRequest  = async () => {
    const data  = {
      'request_term' : termOption,
      'slug': slug
    }
    try {
      console.log(auth.token);
      const response = await addTermAgreementRequest(data, auth.token); // Wait for the promise to resolve
      if (response) {
       // console.log(response.data);
        navigate("/confirm", { state: {confirmationType: "agreement"} });
      } else {
        console.error("Error");
      }
      
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Term Agreement Form
      </Typography>

      {!showAuthForm && (
        <form onSubmit={handleFormSubmit}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Select the length of the term:
          </Typography>
          <RadioGroup
            value={termOption}
            onChange={(e) => setTermOption(e.target.value)}
          >
            {termOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!termOption}
            fullWidth
            sx={{ mt: 3 }}
          >
            Submit
          </Button>
        </form>
      )}
      {showAuthForm && !isRegistered && !isLogged && (
        <Box>
          <RegisterForm
            onSuccess={handleRegistrationSuccess}
            mode={formMode}
          />
        </Box>
      )}
      {isRegistered && !isLogged && (
        <Box>
          <OTPForm onSuccess={handleOTPSuccess} />
        </Box>
      )}
      {/* {showAuthForm && !isLogged && (
        <Box>
          <p>Confirmed</p>
        </Box>
      )
      } */}
    </Box>
  );
};

export default TermAgreementForm;
