import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import moment from "moment";

import timeConverter from "../../Services/Helpers/TimeConverter";
import formatTime from "../../Services/Helpers/FormatTime";
import getDayIDX from "../../Services/Helpers/GetDayIDX";

import {
  globalStyles,
  colors,
  calendarStyles,
  calendar
} from "../../assets/styles/global";

import { TimeRanges } from "../../Services/Constants/timeRanges";

const VertTimeSelect = ({ scheduleData, handleSelectTime }) => {
  const [selectTime, setSelectTime] = useState("");
  const selectedDate = useSelector((state) => state.booking.selectedDate);
  const theme = useTheme();
  const [styles, setStyles] = useState(() => calendar(theme));
  useEffect(() => {
    setStyles(calendar(theme));
    //console.log('Theme changed, updating styles:', calendar(theme));
  }, [theme]);

  //var daySelect: string | null = selectedDate;
  const [daySelect, setDaySelect] = useState(
    moment(selectedDate).format("ddd") ||
    moment(new Date().toISOString().split("T")[0]).format("ddd")
  );

  useEffect(() => {
    setDaySelect(moment(selectedDate).format("ddd"));
    //console.log("daySelect: ", daySelect);
  }, [selectedDate]);

  function isSelectTime(time) {
    let retn = false;

    if (time == selectTime) {
      retn = true;
    }
    return retn;
  }

  function hasRangeTime(range) {
    let retn = false;

    if (daySelect !== null && scheduleData[getDayIDX(daySelect)] != undefined) {
      scheduleData[getDayIDX(daySelect)][daySelect]?.map((timeOption) => {
        if (
          timeConverter(timeOption.time) >= timeConverter(range.start) &&
          timeConverter(timeOption.time) <= timeConverter(range.end)
        ) {
          if (timeOption.select == true) {
            retn = true;
          }
        }
      });
    }
    return retn;
  }

  function isInRange(time, rangeSelect) {
    if (
      timeConverter(time) >= timeConverter(rangeSelect.start) &&
      timeConverter(time) <= timeConverter(rangeSelect.end)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div
        style={{
          ...globalStyles.flexRow,
          ...globalStyles.flexWrap,
          ...globalStyles.fullWidth,
          borderRadius: 15,
          gap: 10,
          width: '95%',
          margin: 'auto',
        }}
      >
        {TimeRanges.map((range, rangeIDX) => {
          return (
            <div
              key={range.name}
              style={{
                ...globalStyles.mt30,
                flex: 1,
                flexBasis: "13%",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div key={rangeIDX} onClick={() => { }}>
                {/* <img
                  src={range.src}
                  style={{
                    ...styles.timeIcon,
                    tintColor: colors.darker_gray.color,
                    ...(hasRangeTime(range)
                      ? styles.selectRange
                      : null),
                    objectFit: "contain",
                  }}
                  alt="range"
                /> */}
                <div
                  style={{
                    ...globalStyles.p,
                    ...styles.rangeLabel
                    //hasRangeTime(range) ? styles.selectRange : null,
                    // hasRangeTime(range) ? styles.selectRange : null as any,
                  }}
                >
                  {range.name}
                </div>
              </div>

              <div
                style={{
                  // borderTopWidth: 2,
                  // borderTopColor: "#373737", //globalStyles.gray.color,
                  marginTop: 10,
                  marginBottom: 10,
                  // borderRadius: 15,
                }}
              ></div>

              {daySelect !== null &&
                scheduleData[getDayIDX(daySelect)] !== undefined &&
                scheduleData[getDayIDX(daySelect)][daySelect]?.map((time, timeIDX) => {
                  //console.log(time);
                  //console.log(getDayIDX(daySelect));
                  //console.log(scheduleData[getDayIDX(daySelect)]);
                  if (isInRange(time.time, range)) {
                    return (
                      <div
                        key={timeIDX}
                        style={{
                          ...globalStyles.p3,
                          marginTop: 10,
                          ...(time.select == true ? styles.availableTime : null),
                          ...(isSelectTime(time) == true ? styles.selectTime : null),
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (time.select) {
                            setSelectTime(time);
                            handleSelectTime(time);
                          }
                        }}
                      >
                        <div
                          style={{
                            ...styles.time, fontSize: 14, fontWeight: "600",
                            ...(time.select === true ? styles.availableTimeText : {}),
                            ...(isSelectTime(time) === true ? styles.selectTimeText : null),
                          }}
                        >
                          {formatTime(time.time)}
                        </div>
                      </div>
                    )
                  }
                })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default VertTimeSelect;

const styles = {
  timeIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
