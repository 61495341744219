import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegEnvelope } from "react-icons/fa6";
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import calendarReschedule from "../../assets/calendar-reschedule.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import {
  formatBookingTime,
  calculateTimeRemaining,
} from "../../Services/Helpers/CalendarHelpers";
import { left } from "glamor";

const CanceledCard = ({ bookingData, hosting, getSchedule }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReschedule = () => {
    const slug = bookingData?.space?.slug;
    if (slug) {
      navigate(`/${slug}`);
    } else {
      console.warn("Slug not available for this booking");
    }
    handleMenuClose();
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        p: 2,
        boxShadow: theme.shadows[1],
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        {/* Menu Button */}
        <IconButton onClick={handleMenuOpen}>
          <BsThreeDotsVertical color={theme.palette.text.primary} size={25} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={menuVisible}
          onClose={handleMenuClose}
          PaperProps={{
            sx: { borderRadius: 2, mt: 1 },
          }}
        >
          <MenuItem onClick={handleReschedule}>
            <img
              src={calendarReschedule}
              style={{ width: 18, height: 18, marginRight: 10 }}
              alt="Calendar Reschedule"
            />
            Reschedule
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose}>
            <FaRegEnvelope
              size={18}
              color={theme.palette.text.primary}
              style={{ marginRight: 10 }}
            />
            Message
          </MenuItem> */}
        </Menu>
      </Box>
      {/* First Row: Trainer Image and Details */}
      {hosting && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box display="flex" alignItems="center" gap={2}>
            {/* Trainer Image */}
            <Avatar
              src={bookingData?.booking_user?.image}
              sx={{ width: 45, height: 45, borderRadius: "50%" }}
              alt="Trainer"
            />
            {/* Trainer Info */}
            <Box sx={{ textAlign: "left" }}>
              <Typography
                variant="body1"
                fontWeight={600}
                color={theme.palette.text.primary}
              >
                {bookingData?.booking_user?.name}{" "}
                {bookingData?.booking_user?.last_name}
              </Typography>
              <Typography variant="body2" color={theme.palette.text.secondary}>
                {bookingData?.booking?.start_time &&
                  (() => {
                    const { startDate, formattedTime } = formatBookingTime(
                      bookingData.booking.start_time,
                      bookingData.booking.end_time
                    );
                    return `${startDate}, ${formattedTime}`;
                  })()}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {/* Gym and Location Details */}
      {!hosting && (
        <Box mt={2} sx={{ textAlign: "left" }}>
          <Typography
            sx={{ mb: 2 }}
            variant="body1"
            color={theme.palette.text.secondary}
          >
            {bookingData?.booking?.start_time &&
              (() => {
                const { startDate, formattedTime } = formatBookingTime(
                  bookingData.booking.start_time,
                  bookingData.booking.end_time
                );
                return `${startDate}, ${formattedTime}`;
              })()}
          </Typography>

          <Typography
            variant="body1"
            fontWeight={600}
            color={theme.palette.text.primary}>
            {bookingData?.space?.name}
          </Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {bookingData?.location?.address} {/* Location */}
          </Typography>
        </Box>
      )}

      {/* Canceled Status */}
      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ textAlign: "right", mb: 1 }}
          >
            {!hosting && (
              <>
                Paid: <b>${bookingData.booking.payout / 100}</b>{" "}
              </>
            )}
            {hosting && (
              <>
                Payout: <b>${bookingData.booking.payout / 100}</b>{" "}
              </>
            )}
          </Typography>
        </Box>
        <Button
          variant="contained"
          disabled
          sx={{
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            textTransform: "none",
            p: "6px 17px",
            fontSize: "0.875rem",
            fontWeight: 500,
            borderRadius: 1,
          }}
        >
          Canceled:{" "}
          {calculateTimeRemaining(bookingData.booking.cancelled_datetime)} ago
        </Button>
      </Box>
    </Box>
  );
};

export default CanceledCard;
