import React, { useEffect, useState, useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import Button from "plaid-threads/Button";

const Link = () => {
  const [linkToken, setLinkToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [items, setItems] = useState([]);
  const [identitys, setIdentitys] = useState([]);
  const [auth, setAuth] = useState([]);
  const [number, setnumber] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isPaymentInitiation, setIsPaymentInitiation] = useState(false);
  const [isCraProductsExclusively, setIsCraProductsExclusively] =
    useState(false);

  useEffect(() => {
    const storedLinkToken = localStorage.getItem("link_token");
    if (storedLinkToken) {
      setLinkToken(storedLinkToken);
    } else {
      console.error("No link token found in localStorage!");
    }
  }, []);

  const exchangePublicTokenForAccessToken = async (public_token) => {
    console.log("Step 5: Sending Public Token", public_token);

    try {
      const response = await fetch(
        "http://localhost:8080/plaidservice/setAccessToken",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: `public_token=${public_token}`,
        }
      );

      if (!response.ok) {
        console.error("Failed to exchange public token for access token");
        return;
      }

      const data = await response.json();
      console.log("Response Data:", data);
      setAccessToken(data.access_token);
    } catch (error) {
      console.error("Error exchanging public token:", error);
    }
  };

  const onSuccess = useCallback(
    (public_token) => {
      console.log("Step 4: Received public token:", public_token);

      if (isPaymentInitiation) {
        setIsPaymentInitiation(false);
      } else if (isCraProductsExclusively) {
        setIsCraProductsExclusively(false);
      } else {
        exchangePublicTokenForAccessToken(public_token);
      }

      setLinkToken("linkSuccess");
      window.history.pushState("", "", "/link");
    },
    [isPaymentInitiation, isCraProductsExclusively]
  );

  let isOauth = false;
  const config = {
    token: linkToken,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  const handleFetchTransactions = async () => {
    if (!accessToken) return;

    console.log("Fetching transactions with access token:", accessToken);

    try {
      const response = await fetch(
        `http://localhost:8080/plaidservice/transactions?access_token=${accessToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch transactions");
        return;
      }

      const data = await response.json();
      console.log("Fetched Transactions:", data);
      setTransactions(data.latest_transactions || []);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const handleFetchItem = async () => {
    if (!accessToken) {
      console.error("Access token not available");
      return;
    }

    console.log("Fetching item details with access token:", accessToken);

    try {
      const response = await fetch(
        `http://localhost:8080/plaidservice/item?access_token=${accessToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch item details");
        return;
      }

      const data = await response.json();
      setItems(data);

      console.log("Fetched Item Details:", data.institution);
    } catch (error) {
      console.error("Error fetching item details:", error);
    }
  };

  const handleFetchAuth = async () => {
    if (!accessToken) {
      console.error("Access token not available");
      return;
    }

    console.log("Fetching item details with access token:", accessToken);

    try {
      const response = await fetch(
        `http://localhost:8080/plaidservice/auth?access_token=${accessToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch item details");
        return;
      }

      const data = await response.json();
      setAuth(data.accounts);
      setnumber(data.numbers);

      console.log("Fetched Auth Details:", data);
    } catch (error) {
      console.error("Error fetching item details:", error);
    }
  };

  const handleFetchIdentity = async () => {
    if (!accessToken) {
      console.error("Access token not available");
      return;
    }

    console.log("Fetching item details with access token:", accessToken);

    try {
      const response = await fetch(
        `http://localhost:8080/plaidservice/identity?access_token=${accessToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch item details");
        return;
      }

      const data = await response.json();
      setIdentitys(data.identity || []);

      console.log("Fetched Item Details:", data);
    } catch (error) {
      console.error("Error fetching item details:", error);
    }
  };

  const handleFetchAccount = async () => {
    if (!accessToken) {
      console.error("Access token is not available.");
      return;
    }

    console.log("Fetching account details with access token:", accessToken);

    try {
      const response = await fetch(
        `http://localhost:8080/plaidservice/accounts?access_token=${accessToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch account details");
        return;
      }

      const data = await response.json();
      setAccounts(data.accounts || []);
      console.log("Fetched Account Details:", data);
      console.log("access Token", accessToken);
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  };

  return (
    <div>
      {!linkToken && <p>Loading link token...</p>}

      {linkToken && !accessToken && (
        <Button type="button" large onClick={open} disabled={!ready}>
          Launch Link
        </Button>
      )}
      {accessToken && (
        <Button type="button" large onClick={handleFetchTransactions}>
          Fetch Transactions
        </Button>
      )}
      {transactions.length > 0 && (
        <div>
          <h2>Transactions</h2>
          <table
            border="1"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th>Account ID</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.account_id}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.date || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {accessToken && (
        <Button type="button" large onClick={handleFetchItem}>
          Item Get
        </Button>
      )}
      {items && items.institution && (
        <div>
          <h2>Institution Details</h2>
          <table
            border="1"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th>Institution Name</th>
                <th>Institution ID</th>
                <th>Products</th>
                <th>Country Codes</th>
                <th>Routing Numbers</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{items.institution.name}</td>
                <td>{items.institution.institution_id}</td>
                <td>{items.institution.products.join(", ")}</td>
                <td>{items.institution.country_codes.join(", ")}</td>
                <td>{items.institution.routing_numbers.join(", ")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {accessToken && (
        <Button type="button" large onClick={handleFetchAccount}>
          Account Get
        </Button>
      )}

      {accounts.length > 0 && (
        <div>
          <h2>Account Details</h2>
          <table
            border="1"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Available Balance</th>
                <th>Subtype</th>
                <th>Mask</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account, index) => (
                <tr key={index}>
                  <td>{account.name}</td>
                  <td>{account.balances?.available || "N/A"}</td>
                  <td>{account.subtype}</td>
                  <td>{account.mask}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {accessToken && (
        <Button type="button" large onClick={handleFetchIdentity}>
          Identity Get
        </Button>
      )}
      {identitys.length > 0 && (
        <div>
          <h2>Identity Details</h2>
          <table
            border="1"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th>Owner Name</th>
                <th>Email Addresses</th>
                <th>Phone Numbers</th>
                <th>Addresses</th>
              </tr>
            </thead>
            <tbody>
              {identitys[0].owners.map((owner, index) => (
                <tr key={index}>
                  <td>{owner.names}</td>
                  <td>
                    {owner.emails && owner.emails.length > 0
                      ? owner.emails.map((email, idx) => (
                          <div key={idx}>{email.data}</div>
                        ))
                      : "N/A"}
                  </td>
                  <td>
                    {owner.phone_numbers && owner.phone_numbers.length > 0
                      ? owner.phone_numbers.map((phone, idx) => (
                          <div key={idx}>{phone.data}</div>
                        ))
                      : "N/A"}
                  </td>
                  <td>
                    {owner.addresses && owner.addresses.length > 0
                      ? owner.addresses.map((address, idx) => (
                          <div key={idx}>
                            {address.data?.street}, {address.data?.city} -{" "}
                            {address.data?.postal_code}, {address.data?.region}
                          </div>
                        ))
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {accessToken && (
        <Button type="button" large onClick={handleFetchAuth}>
          Auth Get
        </Button>
      )}
      {auth.length > 0 && (
        <div>
          <h2>Auth Details</h2>
          <table
            border="1"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Available Balance</th>
                <th>Account Number</th>
                <th>Routing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{auth[0].name}</td>
                <td>{auth[0].balances?.available || "N/A"}</td>
                <td>{number.ach[0].account}</td>
                <td>{number.ach[0].routing}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Link;
