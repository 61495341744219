import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Tab, Tabs as MuiTabs } from '@mui/material';
import { Button } from '../Common/Buttons/Button';
import StarIcon from '@mui/icons-material/Star';
import DetailsSchedule from '../Calendar/DetailsSchedule';
import { convertCentsToDollars } from '../../Services/Helpers/FinanceHelpers';
import TermAgreementForm from './TermAgreementForm';

const OptionsForm = ({
  activeTab,
  handleTabChange,
  availableTabs,
  scheduleData,
  Data,
  daySelect,
  timeSelect,
  openPanel,
  spacingStyles,
}) => {

  const theme = useTheme();

  console.log(Data);

  return (
    <div className="options-form-container">
      <div className="options-form">
        <h2 style={{ marginTop: 'unset' }}>Options to Hop Here</h2>
        <p>Select the Booking option that best fits your business.</p>
        <div>
          <MuiTabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            textColor="primary"
            indicatorColor="primary"
          >
            {availableTabs.map((tabName) => (
              <Tab key={tabName} label={tabName} value={tabName} sx={{ fontSize: "16px", fontWeight: "bold", textTransform: "capitalize" }} />
            ))}
          </MuiTabs>
        </div>
        {activeTab === 'Instant Booking' ? (
          <div style={{ paddingTop: '20px' }}>
            <DetailsSchedule schedule={scheduleData} />
            <div className="footer-profile">
              <div
                className="flexRow flexAround footer-inner"
                style={{ ...spacingStyles.w100 }}
              >
                <div className="flexCol centerDiv">
                  <div className="flexRow price">
                    <span className="h1 white" style={{ fontSize: 45 }}>
                      ${convertCentsToDollars(Data.chargeRate)}
                    </span>
                    <span className="white" style={{ ...spacingStyles.pl5 }}>
                      /booking
                    </span>
                  </div>
                  <h5 className="" style={{ ...spacingStyles.mt0, color: theme.palette.text.primary }}>
                    {" "}
                    <StarIcon /> with membership
                  </h5>
                </div>
                <div
                  style={{ ...spacingStyles.w50 }}
                  onClick={() => {
                    if (daySelect !== null && timeSelect !== null) {
                      openPanel();
                    }
                  }}
                >
                  <Button
                    fill={(daySelect === null || timeSelect === null) ? 'outline' : 'solid'}
                    text="Book Now"
                    disabled={daySelect === null || timeSelect === null}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ minHeight: '300px' }}>

            <TermAgreementForm slug ={Data.slug}></TermAgreementForm>

          </div>
        )}
      </div>
    </div>
  );
};

export default OptionsForm;