import React from "react";
import { IconButton, TextField, InputAdornment, Box, Typography } from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from "@mui/material/styles";
import { formStyles } from "../../assets/styles/global"; // Import global form styles
import { convertCentsToDollars } from "../../Services/Helpers/FinanceHelpers";

export default function RateInput({
  chargeRate,
  handleChargeRateChange,
  incrementChargeRate,
  decrementChargeRate,
  isError = false,  // Optional prop to check for input error
}) {
  const theme = useTheme(); // Access the current theme

  // Conditionally use light/dark styles based on the theme mode
  const localStyle = {
    input: {
      ...formStyles.input,
      backgroundColor: "#ffffff26", // Dark mode background
      color: "#fff", // White text for dark mode
      fontSize: '50px',
    },
    input_light: {
      ...formStyles.input_light,
      backgroundColor: "#0000001c", // Light mode background
      color: "#000", // Dark text for light mode
      fontSize: '50px',
    },
  };
  const inputStyle = theme.palette.mode === 'light' ? localStyle.input_light : localStyle.input;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: 316, width: '100%', justifyContent: 'center' }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto', alignItems: 'center' }}>
        <Typography variant="h4" color={theme.palette.text.secondary} sx={{ fontSize: 40, alignSelf: 'flex-start'  }}>
          $
        </Typography>
        <TextField
          type="number"
          value={convertCentsToDollars(chargeRate)}
          onChange={handleChargeRateChange}
          inputProps={{
            min: 0,
            max: 1000,
            step: 1,
            style: { textAlign: 'center',  ...inputStyle,  }, // Apply global input style based on theme
          }}
          sx={{
            margin: '5px',
            minWidth: '85px',
            '& input': { textAlign: 'center' }, // Ensure input text is centered
            ...(isError ? formStyles.errorInput : {}), // Apply error style if error is present
          }}
          InputProps={{
            disableUnderline: true,
          }}
          variant="standard"
        />
        <Typography variant="h6" color={theme.palette.text.secondary} sx={{ fontSize: 24, alignSelf: 'flex-end', marginBottom: "20px" }}>
          /hour
        </Typography>
      </Box>

      {/* <Box>
        <IconButton onClick={incrementChargeRate} sx={{ color: theme.palette.text.primary }}>
          <ArrowDropUpIcon />
        </IconButton>
        <IconButton onClick={decrementChargeRate} sx={{ color: theme.palette.text.primary }}>
          <ArrowDropDownIcon />
        </IconButton>
      </Box> */}

      {/* Error Text */}
      {isError && (
        <Typography sx={formStyles.errorText}>
          Please enter a valid rate.
        </Typography>
      )}
    </Box>
  );
}
