import React, { useMemo, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material"; // Import Snackbar and Alert

function GuestRoute({ children }) {
  const auth = useSelector((state) => state.auth); // Fetching from Redux state

  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  const [redirectToLogin, setRedirectToLogin] = useState(false); // Control redirect

  // Memoize the userTypeSet value based on auth changes
  const userTypeSet = useMemo(() => {
    if (!auth.isAuthenticated) return true;
    return localStorage.getItem("userTypeSet");
  }, [auth]);

  const isAuthenticated = auth.isAuthenticated;
  const role_id = String(auth.role_id); // Stringify the role_id for comparisons

  // Effect to handle showing Snackbar and navigating to Login
  useEffect(() => {
    if (!isAuthenticated) {
      setOpenSnackbar(true); // Show Snackbar
      setTimeout(() => {
        setRedirectToLogin(true); // Set redirect after 2 seconds
      }, 2000); // 2 seconds delay
    }
  }, [isAuthenticated]);

  // Handle navigation based on the role and userTypeSet status
  if (!isAuthenticated && redirectToLogin) {
    //console.log("Nav Register");
    return <Navigate to="/Register" replace />;
  } else if (!userTypeSet) {
    // Route based on role_id
    switch (role_id) {
      case 2:
        return <Navigate to="/GymDash" />; // Gym user dashboard
      case 3:
        return <Navigate to="/TrainerDash" />; // Trainer onboarding
      default:
        return <Navigate to="UserType" />; // Default user type selection
    }
  }

  return (
    <>
      {children}

      {/* Snackbar for authentication message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="warning" sx={{ width: "100%" }}>
          Authentication required. Please login to continue.
        </Alert>
      </Snackbar>
    </>
  );
}

export default GuestRoute;
