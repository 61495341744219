import React from "react";
import { Button as MUIButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RenderIcons from "../RenderIcons";

export const Button = ({
  fill,
  text,
  onClick,
  disabled = false,
  leftIconName,
  iconWidth = 25,
  iconHeight = 25,
  sx = {},
}) => {
  const theme = useTheme(); // Access current theme for dark/light mode support

  // Define styles for dark and light modes
  const buttonStyles = {
    default: {
      background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
      color: theme.palette.primary.contrastText, // Button text will adjust based on contrastText in the theme
    },
    outline: {
      background: "unset",
      border: `2px solid ${theme.palette.grey[500]}`,
      color: theme.palette.text.primary, // Adjust text color for outline mode
    },
    disabled: {
      background: `linear-gradient(${theme.palette.grey[500]}, ${theme.palette.grey[700]})`,
      color: theme.palette.grey[400], // Text color for disabled state
    },
    confirm: {
      background: theme.palette.gradient.confirm,
      color: theme.common.white, // Text color for inactive state
    },
    select: {
      background: theme.palette.primary.light,
      color: theme.palette.common.white, // Text color for inactive state
    },
    inactive: {
      background: `linear-gradient(${theme.palette.grey[400]}, ${theme.palette.grey[600]})`,
      color: theme.palette.common.white, // Text color for inactive state
    },
  };

  // Conditionally assign styles based on the button state
  let appliedStyle = buttonStyles.default;
  if (disabled) {
    appliedStyle =
      fill === "outline" ? buttonStyles.outline : buttonStyles.disabled;
  } else if (fill === "outline") {
    appliedStyle = buttonStyles.outline;
  } else if (fill === "inactive") {
    appliedStyle = buttonStyles.inactive;
  } else if (fill === "confirm") {
    appliedStyle = buttonStyles.confirm;
  } else if (fill === "select") {
    appliedStyle = buttonStyles.select;
  }

  //console.log(disabled);

  return (
    <MUIButton
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      sx={{
        ...appliedStyle,
        borderRadius: "50px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px 20px",
        cursor: disabled ? "not-allowed" : "pointer",
        ...sx,
      }}
    >
      {!!leftIconName && (
        <RenderIcons
          iconName={leftIconName}
          width={iconWidth}
          height={iconHeight}
          color={disabled ? theme.palette.grey[400] : appliedStyle.color} // Icon color matches text color
          style={{ marginRight: 10 }}
        />
      )}
      <Typography
        variant="button"
        sx={{
          fontWeight: "bold",
          fontSize: 18,
          color: appliedStyle.color, // Text color adapts based on the applied style
        }}
      >
        {text}
      </Typography>
    </MUIButton>
  );
};
