import React, { useEffect, useState, useRef } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { formStyles, spacingStyles } from "../../assets/styles/global";

import { Button } from "../Common/Buttons/Button";

const PaymentForm = ({ clientSecret }) => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const [isProcess, setIsProcess] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {}, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe not loaded");
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.log(submitError);
      return;
    }

    // Use the clientSecret and Elements instance to confirm the setup
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://hopper.fit",
        //https://www.hopper.fit/?payment_intent=pi_3Nw2z7FijxaMq7Qt1vvdUz6H&payment_intent_client_secret=pi_3Nw2z7FijxaMq7Qt1vvdUz6H_secret_HWQHbxKOOQTQZJwJjsTJabELo&redirect_status=succeeded
      },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: "if_required",
    });

    if (error) {
      console.error(error);
    } else {
      console.log(paymentIntent);
      console.log(paymentIntent.metadata);
      navigate("/confirm", { state: { paymentIntent: paymentIntent, confirmationType: "stripe"  } });
    }
  };

  const paymentElementStyle = {
    base: {
      ...formStyles.input,
    },
    invalid: {
      ...formStyles.input,
      ...formStyles.errorInput,
    },
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <PaymentElement options={{ style: paymentElementStyle }} />
      <div className="mt40 pb20">
        {/* <Button text={isProcess ? "Processing..." : "Reserve Now"}
          onClick={handleButtonClick}
        ></Button> */}
        <button type="submit" style={{ ...styles.fullWidth, ...styles.button }}>
          <span style={{ ...styles.buttonText }}>
            {isProcess ? "Processing..." : "Reserve Now"}
          </span>
        </button>
      </div>
    </form>
  );
};

export default PaymentForm;

const styles = {
  button: {
    width: "100%",
    borderRadius: "50px",
    background: "linear-gradient(#F57F3B, #A54C00)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "unset",
    marginTop: '40px',
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
    padding: "15px 20px",
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
};
