// Import necessary libraries and components
import React, { useEffect, useState, useContext } from "react";

import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";

import RenderIcons from "../../Components/Common/RenderIcons";
import { colors, spacingStyles, themeColors } from "../../assets/styles/global";

import jwtDecode from "jwt-decode";

import { getStartTrial } from "../../Services/APIHandlers/CheckoutApi";
import AuthContext from "../../Navigation/AuthWrapper";

import { Button } from "../../Components/Common/Buttons/Button";

import formatPhoneNumber from "../../Services/Helpers/FormatPhoneNumber";
import { useNavigate } from "react-router-dom";
import { addBooking } from "../../Services/APIHandlers/BookingApi";
import { centerCrop } from "react-image-crop";
import { useTheme } from '@mui/material/styles';

const ConfirmationScreen = () => {
  const auth = useContext(AuthContext);
  const navigation = useNavigate();
  const theme = useTheme();

  const selectedDate = useSelector((state) => state.booking.selectedDate);
  const selectedTime = useSelector((state) => state.booking.selectedTime);
  const selectedSite = useSelector((state) => state.booking.selectedSite);

  // Extract URL parameters
  // const { search } = useLocation();
  // const params = new URLSearchParams(search);
  // const paymentIntent = params.get('payment_intent');
  // const clientSecret = params.get('payment_intent_client_secret');
  // const redirectStatus = params.get('redirect_status');

  const location = useLocation();
  const { paymentIntent, confirmationType, customData } = location.state || {};

  const [userData, setUserData] = useState("");
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");

  const checkToken = async () => {
    const token = await localStorage.getItem("token");
    if (token) {
      const userInfo = jwtDecode(token);
      setUserData(userInfo);
      //console.log(userInfo);
    }
  };

  useEffect(() => {
    //console.log(paymentIntent);
    //console.log(selectedDate, selectedTime, selectedSite);

    checkToken();
    if (confirmationType === "stripe") {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Success!");
          setDescription("Payment received.");
          break;

        case "processing":
          setMessage("Payment processing.");
          setDescription(" We will update you when payment is received.");
          break;

        case "requires_payment_method":
          setMessage("Payment failed.");
          setDescription("Please try another payment method.");
          break;

        default:
          setDescription("Something went wrong.");
          break;
      }
    } else if (confirmationType === "agreement") {
      setMessage("Request Confirmed");
      setDescription(
        "Thank you for submitting your request! Our team will contact you soon to confirm the details and discuss the next steps. If you have any questions in the meantime, please don’t hesitate to reach out."
        // `Confirmation for ${customData?.description || "your term agreement ."}`
      );
    }
  }, []);

  const handleSubmit = async () => {
    navigation("/dashboard");
   }; 

  return (
    <div
      className="flexCol flexCenter"
      style={{
        ...spacingStyles.plr20,
        ...spacingStyles.mt40,
        ...spacingStyles.pt40,
        overflow: "auto",
        maxWidth: "640px",
        margin: "auto",
        alignItems: "flex-start",
        textAlign: "left",
      }}
    >
      <RenderIcons
        iconName="confirmCheckmark"
        width={65}
        height={65}
        color={themeColors(theme).green_dark.color}
      />
      <h2 className="success" style={{ ...spacingStyles.mb10, textAlign: "left" }}>
        {message}
      </h2>
      <div  style={{ ...spacingStyles.mt0 }}>
        <p style={{ ...spacingStyles.mt0 }} >
          {description}
        </p>
        <h4>Your Contact Details:</h4>
        <p>Email: {userData.email}</p>
        <p>Phone: {formatPhoneNumber(userData.phone)} </p>
      </div>

      {/* 
      <p className="left light_gray">
        Congratulations on your payment! Expect an email receipt and
        confirmation shortly. Our dedicated team will be in touch with you via
        phone to ensure you're all set for your first booking. Get ready to
        embark on your fitness journey with us!
      </p> */}
      {/* <p>Payment Intent: {paymentIntent}</p>
      <p>Client Secret: {clientSecret}</p>
      <p>Redirect Status: {redirectStatus}</p> */}

        <Button fill="solid" text="Congrats!" onClick={handleSubmit}></Button>

    </div>
  );
};

export default ConfirmationScreen;
