import React, { useMemo, useState, useEffect } from "react";
import { BsEnvelope, BsThreeDotsVertical } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import calendarReschedule from "../../assets/calendar-reschedule.png";
import { rejectBooking } from "../../Services/APIHandlers/ScheduleApi";
import { confirmBooking } from "../../Services/APIHandlers/ScheduleApi";
import getErrorMessage from "../../Services/Helpers/getErrorMessage";
import moment from "moment";

import {
  formatBookingTime,
  calculateTimeRemaining,
} from "../../Services/Helpers/CalendarHelpers";

const PendingCard = ({ bookingData, hosting, getSchedule }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rejecting, setRejecting] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const menuVisible = Boolean(anchorEl);
  const theme = useTheme();

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    console.log("hosting:", hosting);
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRejectBooking = async () => {
    try {
      setRejecting(true);
      const { data } = await rejectBooking(token, bookingData?.booking?.id);
      console.log(">>rejectBooking", data);

      if (data?.error && data?.message) {
        alert(data.message);
      }
    } catch (error) {
      console.error(">>>Error in handleRejectBooking", error);
      alert(getErrorMessage(error));
    } finally {
      setRejecting(false);
      getSchedule();
    }
  };

  const handleConfirmBooking = async () => {
    try {
      setConfirming(true);
      const { data } = await confirmBooking(token, bookingData?.booking?.id);
      console.log(">>confirmBooking", data);

      if (data?.error && data?.message) {
        alert(data.message);
      } else {
        alert("Booking confirmed successfully!");
      }
    } catch (error) {
      console.error(">>>Error in handleConfirmBooking", error);
      alert(getErrorMessage(error));
    } finally {
      setConfirming(false);
      getSchedule();
    }
  };

  const time = useMemo(() => {
    return formatBookingTime(
      bookingData.booking.start_time,
      bookingData.booking.end_time
    );
  }, [bookingData]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        p: 2,
        boxShadow: theme.shadows[1],
      }}
    >
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {!!calculateTimeRemaining(bookingData.booking.startTime) && (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: 1,
              p: "2px 5px",
            }}
          >
            <FaCircle color={theme.palette.success.main} size={13} />
            <Typography
              variant="body2"
              // fontWeight={500}
              color={theme.palette.text.primary}
            >
              {calculateTimeRemaining(bookingData.booking.startTime)}
            </Typography>
          </Box>
        )}

        <IconButton onClick={handleMenuOpen}>
          {/* <BsThreeDotsVertical
            color={theme.palette.text.primary}
            size={25}
            style={{ marginLeft: 5 }}
          /> */}
        </IconButton>
        {/* <Menu
          anchorEl={anchorEl}
          open={menuVisible}
          onClose={handleMenuClose}
          PaperProps={{
            sx: { borderRadius: 2, mt: 1 },
          }}
        >
          <MenuItem onClick={handleMenuClose}>
            <img
              src={calendarReschedule}
              style={{ width: 18, height: 18, marginRight: 10 }}
              alt="Calendar Reschedule"
            />
            Reschedule
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <BsEnvelope
              size={18}
              color={theme.palette.text.primary}
              style={{ marginRight: 10 }}
            />
            Message
          </MenuItem>
        </Menu> */}
      </Box>

      {/* First Row: Time and Menu */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Typography
          variant="body"
          color={theme.palette.text.primary}
          sx={{ textAlign: "left", pl: 2, paddingBottom: "unset" }}
          fontWeight={600}
        >
          {time.startDate}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.text.primary}
          sx={{ textAlign: "left", pl: 2 }}
          fontWeight={600}
        >
          {time.formattedTime}
        </Typography>
      </Box>

      {/* Gym and Location */}
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="body2"
          color={theme.palette.text.primary}
          sx={{ textAlign: "left", pl: 2 }}
          fontWeight={600}
        >
          {bookingData.space.name}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          sx={{ textAlign: "left", pl: 2 }}
        >
          {bookingData.location.address}
        </Typography>
      </Box>
      {/* Bottom Section: User Info and Actions */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        mt={2}
        mb={2}
      >
        {/* User Avatar */}
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={{ textAlign: "left", pl: 1 }}
        >
          {hosting && (
            <>
              <Avatar
                src={bookingData.booking_user?.image}
                alt={`${bookingData.booking_user?.name} ${bookingData.booking_user?.last_name}`}
              />
              <Typography variant="body2" fontWeight={500}>
                {bookingData.booking_user.name}{" "}
                {bookingData.booking_user.last_name}
              </Typography>
            </>
          )}
        </Box>
        {/* Payment Description */}
      </Box>
      <Box>
        <Typography
          variant="body2"
          fontWeight={500}
          sx={{ textAlign: "right", mb: 1 }}
        >
          {!hosting && (
            <>
              Paid: <b>${bookingData.booking.payout / 100}</b>{" "}
            </>
          )}
          {hosting && (
            <>
              Payout: <b>${bookingData.booking.payout / 100}</b>{" "}
            </>
          )}
        </Typography>
      </Box>
      {hosting && (
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button
            variant="contained"
            onClick={handleConfirmBooking}
            enabled={confirming || bookingData.booking.status === "booked"}
            sx={{
              backgroundImage: theme.palette.gradient.confirm,
              color: theme.palette.common.black,
              fontWeight: 500,
              // Apply styles when the button is disabled
              "&.Mui-disabled": {
                //backgroundColor: theme.palette.grey[400], // Custom background for disabled state
                color: theme.palette.text.disabled, // Custom text color for disabled state
              },
            }}
          >
            Accept
          </Button>

          <Button
            variant="outlined"
            onClick={handleRejectBooking}
            enabled={rejecting || bookingData.booking.status === "booked"}
            sx={{
              //borderColor: theme.palette.grey[400],
              backgroundColor: theme.palette.error.main,
              color: theme.palette.text.white,
              fontWeight: 500,
              "&.Mui-disabled": {
                backgroundColor: theme.palette.error.main, // Custom background for disabled state
                color: theme.palette.text.disabled, // Custom text color for disabled state
                borderColor: theme.palette.error.main,
              },
            }}
          >
            Decline
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PendingCard;
