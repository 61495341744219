import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
import ProfilePicUpload from "../../../Components/Onboarding/ProfilePicUpload"; // Import the ProfilePicUpload component
import { updateBusinessAsync } from "../../../Services/Redux/Thunks/updateBusiness.js";
//import { updateProfilePic } from "../../../Services/Redux/Slices/Profile.Slice"; // Assuming you have a slice for updating profile pic

export default function ProfilePic({ nextAction, previousAction, screenNum = 3, screenTotal = 6 }) {
  const dispatch = useDispatch();
  const business = useSelector((state) => state.business); // Get the current business state
  const token = useSelector((state) => state.auth.token); // Get the auth token

  // Function to save responses and update the business information
  const saveResponses = async () => {
    try {
      // Dispatch the async thunk to update the business
      const updatedBusinessData = business;
      const response = await dispatch(updateBusinessAsync({ updatedBusinessData, token }));
      return response;
    } catch (error) {
      console.error("Failed to update business:", error);
    }
  };

  // Function to handle the profile picture upload success
  const handleProfilePicUploadSuccess = (pictureId) => {
    // Dispatch an action to save the pictureId to the user's profile or business
    //dispatch(updateProfilePic({ pictureId, token }));
    console.log("Profile picture uploaded with ID:", pictureId);
  };

  return (
    <>
      <OnboardingTemplate
        component={
          <ProfilePicUpload
            question="Please upload your profile picture"
            onSuccess={handleProfilePicUploadSuccess} // Pass the success handler
          />
        }
        screenNum={screenNum}
        screenTotal={screenTotal}
        nextAction={nextAction}
        previousAction={previousAction}
        title={"Primary Photo"}
        saveResponses={saveResponses} // Function to save responses
      />
    </>
  );
}
