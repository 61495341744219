import React, { useState, } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Link } from "@mui/material";

import { spacingStyles, colors } from "../../assets/styles/global";
import "../../assets/styles/global.css";

import RegisterForm from "../../Components/Forms/RegisterForm";

import CleanWrapper from "../../Components/Common/CleanWrapper";
import AnimatedBackground from "../../Components/Backgrounds/SlidingDiagonals";

export default function Register() {

  const navigation = useNavigate();
  const location = useLocation();

  // Extract the "type" parameter from the URL
  const searchParams = new URLSearchParams(location.search);
  const userType = searchParams.get("type") || "New User";

  const theme = useTheme(); // Access the current theme
  const mode = theme.palette.mode;

  const handleRegistrationSuccess = () => {
    navigation('/OTP');
  };

  return (
    <CleanWrapper>
      <div>
        <AnimatedBackground />
      </div>
      <div className="container guest-two-col" >
      <Box className="side-display">
          <Box
            component="img"
            alt="Login"
            src={theme.palette.mode === 'light'
              ? require('../../assets/auth_display_light.jpg')
              : require('../../assets/auth_display.jpg')}
            sx={{
              opacity: '50%',
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
          {/* Gradient Overlay */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              // background: theme.palette.mode === 'light'
              // ? 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 100%)'
              // : 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%)',
            }}
          />
        </Box>
        <Box className="auth-contain-two-col">
          <RegisterForm onSuccess={handleRegistrationSuccess} mode={mode} userType={userType}  />
          <Box sx={{ paddingBottom: "40px" }}>
            <Typography variant="h6" sx={{ paddingTop: "10px", color: theme.palette.text.secondary }}>
              Already have an account?{" "}
              <Link
                component="button"
                onClick={() => { navigation("/login") }}
                sx={{ color: colors.primary.color, paddingLeft: "5px" }}
              >
                Login Here
              </Link>
            </Typography>
          </Box>
        </Box>

      </div>
    </CleanWrapper>
  );
}
