import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthWrapper } from './Navigation/AuthWrapper';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './assets/styles/global';
import { useState, useEffect } from 'react';

function App() {
  // Initialize dark mode state from localStorage, default to true if not found
  const [darkMode, setDarkMode] = useState(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    return storedDarkMode ? JSON.parse(storedDarkMode) : false;  // Set to false by default (light mode)
  });

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    // Save dark mode preference to localStorage
    localStorage.setItem('darkMode', JSON.stringify(darkMode));

    // Apply dark mode styles
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);


  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div className="App">
        <BrowserRouter>
          <AuthWrapper darkMode={darkMode} toggleDarkMode={toggleDarkMode} setDarkMode={setDarkMode} />
        </BrowserRouter>
        {/* Display the link token response if available */}
        
      </div>
    </ThemeProvider>
  );
}

export default App;
