import axios from "axios";
import config from "../../config";

export const updateSchedule = async (data, token) => {
  const response = await axios.post(
    config.apiUrl + "schedule/updateSchedule",
    {
      data,
      token: token,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );
  return response;
};

export const updateBlackOutDates = async (data, token) => {
  const response = await axios.post(
    config.apiUrl + "schedule/updateBlackoutDates",
    {
      data,
      token: token,
    }
  );
  return response;
};

export const fetchSchedule = async (token) => {
  return axios.get(config.apiUrl + "schedule/getData/" + token);
};

export const getMySchedule = async (token) => {
  return axios.get(config.apiUrl + "schedule/getMySchedule/" + token);
};

export const confirmBookingTime = async (token, bookingId) => {
  return axios.get(
    config.apiUrl + `schedule/confirmBookingTime/${token}/${bookingId}`
  );
};

export const confirmBooking = async (token, bookingId) => {
  return axios.get(
    config.apiUrl + `schedule/confirmBooking/${token}/${bookingId}`
  );
};

export const cancelBooking = async (token, bookingId) => {
  console.log(
    ">>cancel",
    config.apiUrl + `schedule/cancelBooking/${token}/${bookingId}`
  );
  return axios.get(
    config.apiUrl + `schedule/cancelBooking/${token}/${bookingId}`
  );
};

export const rejectBooking = async (token, bookingId) => {
  return axios.get(
    config.apiUrl + `schedule/rejectBooking/${token}/${bookingId}`
  );
};

export const cancel24Booking = async (token, bookingId) => {
  return axios.get(
    config.apiUrl + `schedule/cancel24Booking/${token}/${bookingId}`
  );
};

export const checkInBooking = async (token, bookingId) => {
  return axios.get(
    config.apiUrl + `schedule/checkInBooking/${token}/${bookingId}`
  );
};
