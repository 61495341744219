import axios from "axios";
import config from "../../config";

export const fetchSitesNearMe = async (latitude, longitude) => {
  const response = await axios.get(
    config.apiUrl + `search/allNearMe/${latitude}/${longitude}/`
  );
  return response;
};

export const fetchTrainersNearMe = async (latitude, longitude) => {
  const response = await axios.get(
    config.apiUrl + `search/trainersNearMe/${latitude}/${longitude}/`
  );
  return response;
};

export const getSiteData = async (slug) => {
  //console.log(config.apiUrl + `profile/getDetails/`+ slug);
  const response = await axios.get(
    config.apiUrl + `profile/getDetails/` + slug
  );
  return response;
};

export const getMockData = async () => {
  const response = await axios.get(
    config.apiUrl + `profile/getDetails/BestGymEver`
  );
  return response;
};

export const getMySchedule = async (token) => {
  const response = await axios.get(config.apiUrl + "Schedule/getData/" + token);
  return response;
};

export const confirmBooking = async (data, token) => {
  const response = await axios.post(
    config.apiUrl + "Schedule/confirmBookingTime/",
    { data, token: token }
  );
  return response;
};
export const addBooking = async (data, token) => {
  const response = await axios.post(config.apiUrl + "Schedule/addBooking/", {
    data,
    token
  });
  return response;
};
