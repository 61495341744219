import moment from "moment";

function formatTime(time) {
  //console.log(time);
  if (time !== "12:00:00" && time !== "04:00:00") {
    const currentDate = moment().format("YYYY-MM-DD");
    const combinedDateTime = `${currentDate} ${time}`;
    const selectDate = new Date(combinedDateTime);
    const formatted = moment(selectDate).format("h:mm");
    return formatted;
  } else if (time === "04:00:00") {
    return "4 AM";
  } else if (time === "12:00:00") {
    //return 'Noon';
    return "12 PM";
  }
}

export default formatTime;
