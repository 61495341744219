import React, { createContext, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, useTheme, Popover } from "@mui/material";
import { RenderRoutes, RenderMenu } from "./RenderNavigation";
import { clearAuth } from "../Services/Redux/Slices/Auth.Slice";
import { getLoginData } from "../Services/APIHandlers/ProfileApi";

// Auth context
const AuthContext = createContext();
export default AuthContext;

// Snackbar context for global notifications
const SnackbarContext = createContext();

export const AuthWrapper = ({ darkMode, toggleDarkMode, setDarkMode }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role_id = Number(useSelector((state) => state.auth.role_id));

  const theme = useTheme(); // Access the current theme

  // Completion status for steps
  const [completionStatus, setCompletionStatus] = useState({
    Description: false,
    Gallery: false,
    Space: false,
    Amenities: false,
    Location: false,
    Schedule: false,
    Special: false,
    SetupComplete: false,
  });

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Mark step as complete
  const markAsComplete = (step) => {
    setCompletionStatus((prevState) => ({
      ...prevState,
      [step]: true,
    }));
  };

  // Logout handler
  const logout = () => {
    dispatch(clearAuth());
    window.location.reload();
  };

  // Login handler
  const login = () => {
    navigation("/Login");
  };

  // Fetch login data if authenticated
  useEffect(() => {
    if (token && isAuthenticated) {
      getLoginData(token);
    }
  }, [isAuthenticated, token]);

  // Snackbar utility functions
  const showSnackbar = (message, severity = "info") => {
    console.log("Show snackbar");
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const hideSnackbar = () => {
    setSnackbarOpen(false);
  };

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://widgets.leadconnectorhq.com/loader.js";
  //   script.setAttribute("data-resources-url", "https://widgets.leadconnectorhq.com/chat-widget/loader.js");
  //   script.setAttribute("data-widget-id", "670e47d3543511bd154b039e");
  //   script.async = true;

  //   // Create the <div> for the chat widget
  //   const chatDiv = document.createElement("div");
  //   chatDiv.setAttribute("data-chat-widget", "");
  //   chatDiv.setAttribute("data-widget-id", "670e47d3543511bd154b039e");
  //   chatDiv.setAttribute("data-location-id", "Ch6cqf3qvNvyfOQDvdvn");

  //   chatDiv.style.marginTop = "100px";

  //   // Append both <div> and script to the body
  //   document.body.appendChild(chatDiv);
  //   document.body.appendChild(script);


  //   // Custom styling for the chat widget
  //   const applyCustomStyles = () => {
  //     const chatWidgetBubble = document.querySelector('.lc_text-widget--bubble'); // Replace with actual widget button selector
  //     if (chatWidgetBubble) {
  //       chatWidgetBubble.style.paddingTop = "60px"; // Example custom style
  //     }
  //   };

  //   // Check periodically if the widget is loaded, then apply styles
  //   const intervalId = setInterval(() => {
  //     applyCustomStyles();
  //   }, 500);

  //   // Stop checking after 5 seconds
  //   setTimeout(() => {
  //     clearInterval(intervalId);
  //   }, 5000);

  //   // Cleanup script and interval on component unmount
  //   // return () => {
  //   //   document.body.removeChild(script);
  //   //   document.body.removeChild(chatDiv);
  //   //   clearInterval(intervalId);
  //   // };
  //   return () => {
  //     if (script.parentNode === document.body) {
  //       document.body.removeChild(script);
  //     }
  //     if (chatDiv.parentNode === document.body) {
  //       document.body.removeChild(chatDiv);
  //     }
  //     clearInterval(intervalId);
  //   };

  // }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        role_id,
        logout,
        login,
        darkMode,
        setDarkMode,
        toggleDarkMode,
        markAsComplete,
      }}
    >
      <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
        <RenderMenu role_id={role_id} />
        <div className={`content-container`}>
          <RenderRoutes />
        </div>
        <Popover
          id="simple-popover"
          open={snackbarOpen}
          anchorReference="anchorPosition" // Set custom anchor positioning
          anchorPosition={{ top: 50, left: window.innerWidth / 2 }} // Centered horizontally
          elevation={1}
          onClose={hideSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.background.paper, // Match background color
              boxShadow: theme.shadows[1], // Match elevation
              marginTop: "20px",
              padding: "10px", // Adjust padding if needed
            },
          }}
        >
          <Typography
            sx={{
              p: 2,
              color: "black",
              textAlign: "center",
            }}
          >
            {snackbarMessage}
          </Typography>
        </Popover>
      </SnackbarContext.Provider>
    </AuthContext.Provider>
  );
};

// Custom hook for using the Snackbar
export const useSnackbar = () => useContext(SnackbarContext);
