import { createSlice } from "@reduxjs/toolkit";
import { updateScheduleAsync } from "../Thunks/updateSchedule";

const initialState = {
  Sun: [
    { time: "04:00:00", select: false },
    { time: "04:30:00", select: false },
    { time: "05:00:00", select: false },
    { time: "05:30:00", select: false },
    { time: "06:00:00", select: false },
    { time: "06:30:00", select: false },
    { time: "07:00:00", select: false },
    { time: "07:30:00", select: false },
    { time: "08:00:00", select: false },
    { time: "08:30:00", select: false },
    { time: "09:00:00", select: false },
    { time: "09:30:00", select: false },
    { time: "10:00:00", select: false },
    { time: "10:30:00", select: false },
    { time: "11:00:00", select: false },
    { time: "11:30:00", select: false },
    { time: "12:00:00", select: false },
    { time: "12:30:00", select: false },
    { time: "13:00:00", select: false },
    { time: "13:30:00", select: false },
    { time: "14:00:00", select: false },
    { time: "14:30:00", select: false },
    { time: "15:00:00", select: false },
    { time: "15:30:00", select: false },
    { time: "16:00:00", select: false },
    { time: "16:30:00", select: false },
    { time: "17:00:00", select: false },
    { time: "17:30:00", select: false },
    { time: "18:00:00", select: false },
    { time: "18:30:00", select: false },
    { time: "19:00:00", select: false },
    { time: "19:30:00", select: false },
    { time: "20:00:00", select: false },
    { time: "20:30:00", select: false },
    { time: "21:00:00", select: false },
    { time: "21:30:00", select: false },
    { time: "22:00:00", select: false },
    { time: "22:30:00", select: false },
    { time: "23:00:00", select: false },
    { time: "23:30:00", select: false }
  ],
  Mon: [
    { time: "04:00:00", select: false },
    { time: "04:30:00", select: false },
    { time: "05:00:00", select: false },
    { time: "05:30:00", select: false },
    { time: "06:00:00", select: false },
    { time: "06:30:00", select: false },
    { time: "07:00:00", select: false },
    { time: "07:30:00", select: false },
    { time: "08:00:00", select: false },
    { time: "08:30:00", select: false },
    { time: "09:00:00", select: false },
    { time: "09:30:00", select: false },
    { time: "10:00:00", select: false },
    { time: "10:30:00", select: false },
    { time: "11:00:00", select: false },
    { time: "11:30:00", select: false },
    { time: "12:00:00", select: false },
    { time: "12:30:00", select: false },
    { time: "13:00:00", select: false },
    { time: "13:30:00", select: false },
    { time: "14:00:00", select: false },
    { time: "14:30:00", select: false },
    { time: "15:00:00", select: false },
    { time: "15:30:00", select: false },
    { time: "16:00:00", select: false },
    { time: "16:30:00", select: false },
    { time: "17:00:00", select: false },
    { time: "17:30:00", select: false },
    { time: "18:00:00", select: false },
    { time: "18:30:00", select: false },
    { time: "19:00:00", select: false },
    { time: "19:30:00", select: false },
    { time: "20:00:00", select: false },
    { time: "20:30:00", select: false },
    { time: "21:00:00", select: false },
    { time: "21:30:00", select: false },
    { time: "22:00:00", select: false },
    { time: "22:30:00", select: false },
    { time: "23:00:00", select: false },
    { time: "23:30:00", select: false }
  ],
  Tue: [
    { time: "04:00:00", select: false },
    { time: "04:30:00", select: false },
    { time: "05:00:00", select: false },
    { time: "05:30:00", select: false },
    { time: "06:00:00", select: false },
    { time: "06:30:00", select: false },
    { time: "07:00:00", select: false },
    { time: "07:30:00", select: false },
    { time: "08:00:00", select: false },
    { time: "08:30:00", select: false },
    { time: "09:00:00", select: false },
    { time: "09:30:00", select: false },
    { time: "10:00:00", select: false },
    { time: "10:30:00", select: false },
    { time: "11:00:00", select: false },
    { time: "11:30:00", select: false },
    { time: "12:00:00", select: false },
    { time: "12:30:00", select: false },
    { time: "13:00:00", select: false },
    { time: "13:30:00", select: false },
    { time: "14:00:00", select: false },
    { time: "14:30:00", select: false },
    { time: "15:00:00", select: false },
    { time: "15:30:00", select: false },
    { time: "16:00:00", select: false },
    { time: "16:30:00", select: false },
    { time: "17:00:00", select: false },
    { time: "17:30:00", select: false },
    { time: "18:00:00", select: false },
    { time: "18:30:00", select: false },
    { time: "19:00:00", select: false },
    { time: "19:30:00", select: false },
    { time: "20:00:00", select: false },
    { time: "20:30:00", select: false },
    { time: "21:00:00", select: false },
    { time: "21:30:00", select: false },
    { time: "22:00:00", select: false },
    { time: "22:30:00", select: false },
    { time: "23:00:00", select: false },
    { time: "23:30:00", select: false }
  ],
  Wed: [
    { time: "04:00:00", select: false },
    { time: "04:30:00", select: false },
    { time: "05:00:00", select: false },
    { time: "05:30:00", select: false },
    { time: "06:00:00", select: false },
    { time: "06:30:00", select: false },
    { time: "07:00:00", select: false },
    { time: "07:30:00", select: false },
    { time: "08:00:00", select: false },
    { time: "08:30:00", select: false },
    { time: "09:00:00", select: false },
    { time: "09:30:00", select: false },
    { time: "10:00:00", select: false },
    { time: "10:30:00", select: false },
    { time: "11:00:00", select: false },
    { time: "11:30:00", select: false },
    { time: "12:00:00", select: false },
    { time: "12:30:00", select: false },
    { time: "13:00:00", select: false },
    { time: "13:30:00", select: false },
    { time: "14:00:00", select: false },
    { time: "14:30:00", select: false },
    { time: "15:00:00", select: false },
    { time: "15:30:00", select: false },
    { time: "16:00:00", select: false },
    { time: "16:30:00", select: false },
    { time: "17:00:00", select: false },
    { time: "17:30:00", select: false },
    { time: "18:00:00", select: false },
    { time: "18:30:00", select: false },
    { time: "19:00:00", select: false },
    { time: "19:30:00", select: false },
    { time: "20:00:00", select: false },
    { time: "20:30:00", select: false },
    { time: "21:00:00", select: false },
    { time: "21:30:00", select: false },
    { time: "22:00:00", select: false },
    { time: "22:30:00", select: false },
    { time: "23:00:00", select: false },
    { time: "23:30:00", select: false }
  ],
  Thu: [
    { time: "04:00:00", select: false },
    { time: "04:30:00", select: false },
    { time: "05:00:00", select: false },
    { time: "05:30:00", select: false },
    { time: "06:00:00", select: false },
    { time: "06:30:00", select: false },
    { time: "07:00:00", select: false },
    { time: "07:30:00", select: false },
    { time: "08:00:00", select: false },
    { time: "08:30:00", select: false },
    { time: "09:00:00", select: false },
    { time: "09:30:00", select: false },
    { time: "10:00:00", select: false },
    { time: "10:30:00", select: false },
    { time: "11:00:00", select: false },
    { time: "11:30:00", select: false },
    { time: "12:00:00", select: false },
    { time: "12:30:00", select: false },
    { time: "13:00:00", select: false },
    { time: "13:30:00", select: false },
    { time: "14:00:00", select: false },
    { time: "14:30:00", select: false },
    { time: "15:00:00", select: false },
    { time: "15:30:00", select: false },
    { time: "16:00:00", select: false },
    { time: "16:30:00", select: false },
    { time: "17:00:00", select: false },
    { time: "17:30:00", select: false },
    { time: "18:00:00", select: false },
    { time: "18:30:00", select: false },
    { time: "19:00:00", select: false },
    { time: "19:30:00", select: false },
    { time: "20:00:00", select: false },
    { time: "20:30:00", select: false },
    { time: "21:00:00", select: false },
    { time: "21:30:00", select: false },
    { time: "22:00:00", select: false },
    { time: "22:30:00", select: false },
    { time: "23:00:00", select: false },
    { time: "23:30:00", select: false }
  ],
  Fri: [
    { time: "04:00:00", select: false },
    { time: "04:30:00", select: false },
    { time: "05:00:00", select: false },
    { time: "05:30:00", select: false },
    { time: "06:00:00", select: false },
    { time: "06:30:00", select: false },
    { time: "07:00:00", select: false },
    { time: "07:30:00", select: false },
    { time: "08:00:00", select: false },
    { time: "08:30:00", select: false },
    { time: "09:00:00", select: false },
    { time: "09:30:00", select: false },
    { time: "10:00:00", select: false },
    { time: "10:30:00", select: false },
    { time: "11:00:00", select: false },
    { time: "11:30:00", select: false },
    { time: "12:00:00", select: false },
    { time: "12:30:00", select: false },
    { time: "13:00:00", select: false },
    { time: "13:30:00", select: false },
    { time: "14:00:00", select: false },
    { time: "14:30:00", select: false },
    { time: "15:00:00", select: false },
    { time: "15:30:00", select: false },
    { time: "16:00:00", select: false },
    { time: "16:30:00", select: false },
    { time: "17:00:00", select: false },
    { time: "17:30:00", select: false },
    { time: "18:00:00", select: false },
    { time: "18:30:00", select: false },
    { time: "19:00:00", select: false },
    { time: "19:30:00", select: false },
    { time: "20:00:00", select: false },
    { time: "20:30:00", select: false },
    { time: "21:00:00", select: false },
    { time: "21:30:00", select: false },
    { time: "22:00:00", select: false },
    { time: "22:30:00", select: false },
    { time: "23:00:00", select: false },
    { time: "23:30:00", select: false }
  ],
  Sat: [
    { time: "04:00:00", select: false },
    { time: "04:30:00", select: false },
    { time: "05:00:00", select: false },
    { time: "05:30:00", select: false },
    { time: "06:00:00", select: false },
    { time: "06:30:00", select: false },
    { time: "07:00:00", select: false },
    { time: "07:30:00", select: false },
    { time: "08:00:00", select: false },
    { time: "08:30:00", select: false },
    { time: "09:00:00", select: false },
    { time: "09:30:00", select: false },
    { time: "10:00:00", select: false },
    { time: "10:30:00", select: false },
    { time: "11:00:00", select: false },
    { time: "11:30:00", select: false },
    { time: "12:00:00", select: false },
    { time: "12:30:00", select: false },
    { time: "13:00:00", select: false },
    { time: "13:30:00", select: false },
    { time: "14:00:00", select: false },
    { time: "14:30:00", select: false },
    { time: "15:00:00", select: false },
    { time: "15:30:00", select: false },
    { time: "16:00:00", select: false },
    { time: "16:30:00", select: false },
    { time: "17:00:00", select: false },
    { time: "17:30:00", select: false },
    { time: "18:00:00", select: false },
    { time: "18:30:00", select: false },
    { time: "19:00:00", select: false },
    { time: "19:30:00", select: false },
    { time: "20:00:00", select: false },
    { time: "20:30:00", select: false },
    { time: "21:00:00", select: false },
    { time: "21:30:00", select: false },
    { time: "22:00:00", select: false },
    { time: "22:30:00", select: false },
    { time: "23:00:00", select: false },
    { time: "23:30:00", select: false }
  ]
};

const ScheduleSlice = createSlice({
  name: "Schedule",
  initialState: initialState,
  reducers: {
    removeSchedule(state) {
      state = initialState
    },
    toggleTime(state, action) {

    },
    addSchedule(state, action) {
     //console.log("Update Schedule Slice", action.payload);
      return action.payload
    },
    setSchedule(state, action){

      const newState = { ...state };

      // Iterate over the response scheduleData
      action.payload.forEach((schedule) => {
        const { day, times } = schedule;
    
        // Ensure the day exists in the state
        if (newState[day]) {
          // Create a map of times for quick lookup
          const timesMap = new Map(times.map((timeObj) => [timeObj.time, timeObj.select]));
    
          // Update the state's day times
          newState[day] = newState[day].map((timeSlot) => ({
            ...timeSlot,
            select: timesMap.get(timeSlot.time) || false, // Set to true if in API response, otherwise false
          }));
        }
      });
      //console.log(newState);
      return newState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateScheduleAsync.pending, (state) => {
        //state.status = 'loading';
      })
      .addCase(updateScheduleAsync.fulfilled, (state, action) => {
        //state.status = 'succeeded';
        console.log("Schedule Update: ", action.payload);
        return action.payload.data;
      })
      .addCase(updateScheduleAsync.rejected, (state, action) => {
        //state.status = 'failed';
        //state.error = action.payload; // Capture and store the error
      });
  },
})

export const { addSchedule, toggleTime, setSchedule } = ScheduleSlice.actions

export const selectScheduleState = (state) => state.schedule;

export default ScheduleSlice.reducer